<template>
  <div class="cs-container" style="background-color: white;">
    <div class="d-print-none mb-2 flex items-center justify-end" style="margin-right:80px">
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <h3 class="text-center title-color">
        <strong>PHIẾU XÉT NGHIỆM ĐỘT BIẾN GEN</strong>
      </h3>
      <span class="close-icon d-print-none" @click="backToDetail()">&times;</span>
    </div>

    <div id="printOrderForm">
      <!-- FIRST ROW -->
      <b-row>
        <b-col class="container-border">
          <!-- PATIENT INFORMATION -->
          <div>
            <span class="cs-title cs-border-b">
              <strong>1.THÔNG TIN BỆNH NHÂN</strong>
            </span>
            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-6">
                Họ tên (IN HOA):
                <span class="cs-content" v-html="form.full_name"></span>
              </span>
              <span class="cs-flex col-3">
                Ngày sinh:
                <span class="cs-content">{{ handleFormatDate(form.date) }}</span>
              </span>
              <span class="cs-flex col-3">
                Giới tính:
                <div class="d-flex cs-content">
                  <div class="mr-1 cs-checkbox" :set="isMale=form.gender===1">
                    <input v-model="isMale" id="male" name="male" type="checkbox" />
                    <label>Nam</label>
                  </div>
                  <div class="mr-1 cs-checkbox" :set="isFemale= form.gender===2">
                    <input v-model="isFemale" id="female" name="male" type="checkbox" />
                    <label>Nữ</label>
                  </div>
                </div>
              </span>
            </div>
            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-6">
                Địa chỉ:
                <span class="cs-content" v-html="form.acc_address"></span>
              </span>
              <span class="cs-flex col-3">
                Điện thoại:
                <span class="cs-content" v-html="form.bill_phone"></span>
              </span>
              <span class="cs-flex col-3">
                Hút thuốc:
                <div class="d-flex cs-content">
                  <div class="mr-1 cs-checkbox" :set="isHospital=form.smoke ===1">
                    <input v-model="isHospital" id="hospital" name="hospital" type="checkbox" />
                    <label>Có</label>
                  </div>
                  <div class="mr-1 cs-checkbox" :set="isSelfPay= form.smoke ===2">
                    <input v-model="isSelfPay" id="self-pay" name="self-pay" type="checkbox" />
                    <label>Không</label>
                  </div>
                </div>
              </span>
            </div>

            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-3">
                Bác sĩ:
                <span class="cs-content" v-html="form.doctor"></span>
              </span>
              <span class="cs-flex col-4">
                Bệnh viện:
                <span class="cs-content" v-html="form.hospital"></span>
              </span>
              <span class="cs-flex col-4">
                Email:
                <span class="cs-content" v-html="form.patient_email"></span>
              </span>
            </div>

            <div class="cs-flex">
              <span class="cs-label">
                <strong>Mã XN:</strong>
              </span>
              <span class="cs-content" v-html="form.factory_code"></span>
            </div>
          </div>
          <div>
            <span class="cs-title cs-border-b cs-border-t">2.THÔNG TIN BỆNH HỌC</span>
            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-12">
                Chẩn đoán lâm sàng:
                <span class="cs-content" v-html="form.diagnosis"></span>
              </span>
            </div>
            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-6">
                Giai đoạn bệnh:
                <span class="cs-content" v-html="form.disease_phase"></span>
              </span>
              <span class="cs-flex col-7">
                Kết quả giải phẫu bệnh:
                <span class="cs-content" v-html="form.operate_result"></span>
              </span>
            </div>
            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-12">
                Vị trí, kích thước độ biệt hóa của khối u:
                <span
                  class="cs-content"
                  v-html="form.position"
                ></span>
              </span>
            </div>
            <div class="cs-width-full container-border-2 flex flex-row">
              <span class="cs-flex col-6">
                Thời gian phát hiện:
                <span class="cs-content" v-html="form.discover_time"></span>
              </span>
              <span class="cs-flex col-6">
                Đã được điều trị gì:
                <span class="cs-content" v-html="form.treatment"></span>
              </span>
            </div>
          </div>
          <!-- REFERRING PHYSICIAN INFORMATION -->
          <!-- ACCOUNT INFORMATION -->
          <div>
            <span class="cs-title cs-border-b cs-border-t">3.THÔNG TIN BỆNH PHẨM VÀ XÉT NGHIỆM</span>
            <div class="cs-flex cs-border-b">
              <div class="d-flex cs-content">
                <span class="cs-label">
                  <strong>Loại bệnh phẩm:</strong>
                </span>
                <div
                  class="mr-1 cs-checkbox cs-check"
                  :set="isDiseaseSample=form.disease_sample === 1"
                >
                  <input v-model="isDiseaseSample" id="sample1" name="sample1" type="checkbox" />
                  <label>
                    Mô sinh thiết/
                    FFPE
                  </label>
                </div>
                <div
                  class="mr-1 cs-checkbox cs-check"
                  :set="isDiseaseSample= form.disease_sample === 2"
                >
                  <input v-model="isDiseaseSample" id="sample2" name="sample2" type="checkbox" />
                  <label>
                    Máu
                    (STL-ctDNA)
                  </label>
                </div>
                <div class="mr-1 cs-checkbox cs-check" :set="isInsurance=form.disease_sample === 3">
                  <input v-model="isInsurance" id="insurance" name="insurance" type="checkbox" />
                  <label>
                    Dịch màng phổi/
                    bụng
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Patient Information and test -->
          <div class="cs-width-full container-border-2 flex flex-row">
            <span class="cs-flex col-4">
              Mã số GPB:
              <span class="cs-content" v-html="form.gpb_code"></span>
            </span>
            <span class="cs-flex col-6">
              Ngày lấy mẫu:
              <span class="cs-content">{{ handleFormatDate(form.sample_day) }}</span>
            </span>
          </div>
          <div>
            <span
              class="cs-content cs-sample"
            >(Mẫu máu chỉ áp dụng với ung thư phổi, đại tràng, buồng trứng, dạ dày)</span>
          </div>
          <div class="mb-2">
            <span class="cs-content cs-sample">
              <strong>Loại ung thư và panel</strong> xét nghiệm: (Vui lòng tích chọn 01 loại)
            </span>
          </div>
          <div class="check-box-master">
            <div class="check-box-1">
              <div class="mr-1 cs-checkbox" :set="isLungCancer=form.sample_type===1">
                <input v-model="isInsurance" id="lung-cancer" name="lung-cancer" type="checkbox" />
                <label>Ung thư phổi: EGFR, ALK, ROS1, KRAS, BRAF, NTRK1, 2, 3 fusion, MET, HER2, NRAS</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isColonCancer=form.sample_type===2">
                <input
                  v-model="isColonCancer"
                  id="colon-cancer"
                  name="colon-cancer"
                  type="checkbox"
                />
                <label>Ung thư đại tràng: NRAS, KRAS, BRAF, HER2, PIK3CA, NTRK1</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isBreastCancer=form.sample_type===3">
                <input
                  v-model="isBreastCancer"
                  id="breast-cancer"
                  name="breast-cancer"
                  type="checkbox"
                />
                <label>Ung thư vú: BRCA1, BRCA2, PIK3CA, ESR1, HER2, NTRK 1/2/3 fusion</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isStomachCancer=form.sample_type===4">
                <input
                  v-model="isStomachCancer"
                  id="stomach-cancer"
                  name="stomach-cancer"
                  type="checkbox"
                />
                <label>Ung thư dạ dày: NTRK1, 2, 3 fusion, HER2, PIK3CA, EGFR, MET</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isArmorCancer=form.sample_type===5">
                <input
                  v-model="isArmorCancer"
                  id="armor-cancer"
                  name="armor-cancer"
                  type="checkbox"
                />
                <label>Ung thư tuyến giáp: BRAF, NTRK1, 2, 3 fusion, ALK, MET</label>
              </div>
            </div>
            <div class="check-box-2 ml-1">
              <div class="mr-1 cs-checkbox" :set="isEggCancer=form.sample_type===6">
                <input v-model="isEggCancer" id="egg-cancer" name="egg-cancer" type="checkbox" />
                <label>Ung thư buồng trứng: BRAF, BRCA1, BRCA2, NTRK1, 2, 3 fusion</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isFrontCancer=form.sample_type===7">
                <input
                  v-model="isFrontCancer"
                  id="front-cancer"
                  name="front-cancer"
                  type="checkbox"
                />
                <label>Ung thư tiền liệt: BRCA1, BRCA2, NTRK1/2/3 fusion, AR</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isNeckCancer=form.sample_type===8">
                <input v-model="isNeckCancer" id="neck-cancer" name="neck-cancer" type="checkbox" />
                <label>Ung thư cổ tử cung: NTRK1, 2, 3 fusion</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isSpineCancer=form.sample_type===9">
                <input
                  v-model="isSpineCancer"
                  id="spine-cancer"
                  name="spine-cancer"
                  type="checkbox"
                />
                <label>Ung thư tụy: BRCA1, BRCA2, NTRK1/2/3 fusion, KRAS, NRAS, BRAF</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isPadTumor=form.sample_type===10">
                <input
                  v-model="isPadTumor"
                  id="tumour-tissues"
                  name="tumour-tissues"
                  type="checkbox"
                />
                <label>U mô đệm đường tiêu hóa GIST: KIT, PDGFRA</label>
              </div>
            </div>
          </div>
          <div>
            <span
              class="cs-label"
            >Các mẫu ung thư phổi bắt đầu/đang điều trị với TKI hoặc bệnh tiến triển (kháng điều trị) được đưa vào nghiên cứu tìm hiểu cơ chế kháng TKI. Xin lựa chọn 1 trong 3 nhóm đối tượng bn dưới đây:</span>
          </div>
          <div class="cs-flex cs-border-b tki-section">
            <span class="cs-label">
              Tên TKI
              <span class="cs-content" v-html="form.tki_code"></span>
            </span>
            <div class="d-flex cs-content cs-treatment">
              <div class="mr-1 cs-checkbox" :set="isTkiOne=form.tki===1">
                <input v-model="isTkiOne" id="tki1" name="tki1" type="checkbox" />
                <label>Bắt đầu điều trị</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isTkiTwo= form.tki===2">
                <input v-model="isTkiTwo" id="tki2" name="tki2" type="checkbox" />
                <label>Điều trị ổn định(trên 3 tháng)</label>
              </div>
              <div class="mr-1 cs-checkbox" :set="isTkiThree=form.tki===3">
                <input v-model="isTkiThree" id="tki3" name="tki3" type="checkbox" />
                <label>
                  Kháng trị(từ khi nào, biểu hiện:)
                  <span
                    class="cs-content"
                    v-html="form.force_treatment"
                  ></span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <span class="cs-content">
              Nghiên cứu: Chúng tôi có thể sử dụng mẫu dư và các thông tin đã loại bỏ danh tính của người làm xét nghiệm vào mục đích nghiên cứu.
              <br />Các nghiên cứu này có thể được công bố trên tạp chí khoa học hoặc sử dụng để phát triển các sản phẩm dịch vụ của chúng tôi. Người làm xét nghiệm không được thông báo hoặc trả tiền cho các hoạt động nghiên cứu này.
            </span>
          </div>
          <!-- BILLING INFORMATION -->
          <!-- SPECIMEN INFORMATION -->
          <div>
            <span
              class="cs-title cs-border-b cs-border-t"
            >4.ĐỒNG Ý CỦA NGƯỜI LÀM XÉT NGHIỆM VÀ BÁC SĨ</span>
          </div>
          <div class="flex-letter">
            <div class="flex-left">
              Tôi ủy quyền cho PXN DTYH thực hiện giải trình tự gen trên mẫu
              <br />của tôi/ người thân của tôi. Những rủi ro, lợi ích và những hạn
              <br />chế của dịch vụ đã được giải thích đầy đủ.
              <br />
              <div class="mx-auto">Ngày................ tháng................ năm 20...</div>
              <br />
              <div class="mx-auto">Chữ ký bệnh nhân hoặc người thân</div>
            </div>
            <div class="flex-right ml-6 mx-auto">
              Tôi đã giải thích đầy đủ về xét nghiệm bao gồm rủi ro, lợi ích
              <br />và lựa chọn thay thế cho bệnh nhân/ người thân bn dựa trên
              <br />pháp lý và trả lời tất cả các câu hỏi của họ
              <br />
              <div class="mx-auto">Ngày................ tháng................ năm 20...</div>
              <br />
              <strong class="mx-auto">Chữ ký bác sĩ</strong>
            </div>
          </div>
          <div class="bottom">
            <div class="left">Nhân viên phụ trách ...........................................
              <span class="cs-content" v-html="form.employees"></span>
            </div>
            <div class="right">Nơi cho mượn bệnh phẩm.......................
              <span class="cs-content" v-html="form.borrow_place"></span>
            </div>
          </div>
          <div class="html2pdf__page-break"></div>
          <div>
            <div>
              <span class="cs-title cs-border-b cs-border-t above">
                <strong>5. NGUYÊN LÝ XÉT NGHIỆM</strong>
              </span>
              <span
                class="cs-border-b cs-border-t letter"
              >ADN được tách chiết từ mẫu xét nghiệm và tạo thư viện giải trình tự sử dụng bộ kit NEBNext hay Accels 25 (Hoa Kỳ). Sau đó, các phân mảnh ADN của vùng gen mục tiêu được làm giàu bởi mẫu dò đặc hiệu của IDTDNA (Hoa Kỳ) và tiến hành giải trình tự thế hệ mới trên hệ thống NextSeq, Illumina (Hoa Kỳ) tai độ phủ trung bình 100x. Kết quả giải trình tự được đối chiếu với bộ gen tham chiếu GRCh38 để xác định đột biến có ý nghĩa lâm sàng. Đột biến có ý nghĩa lâm sàng dựa trên các loại thuốc được chấp thuận bởi Cơ quan quản lý thuốc và thực phẩm Hoa Kỳ (FDA) và hướng dẫn của Mạng lưới Ung thư Quốc gia Hoa Kỳ (NCCN)</span>
            </div>
            <span class="cs-title cs-border-b cs-border-t part-6">
              <strong>HẠN CHẾ CỦA XÉT NGHIỆM</strong>
            </span>
            <div class="letter">
              <span>
                1.Xét nghiệm chỉ khảo sát các vùng mã hóa protein (exon, mang 85% tổng số các biến thể gây bệnh), vùng giao nhau giữa exon
                và intron (splicing site) và phát hiện các đột biến điểm, các mất đoạn/lặp đoạn nhỏ(kích thước không quá 20bp) của các gen được kiểm tra
                <br />2.Chỉ các đột biến có ý nghĩa lâm sàng có tần suất cao hơn tần suất phát hiện (5% với mẫu mô và 1% với DNA tự do) được báo
                trong kết quả. Các đột biến tần suất thấp hơn (nếu có) sẽ không được cung cấp trong báo cáo này.
                <br />3.Xét nghiệm không có khả năng phát hiện: các đột biến nằm ngoài vùng mã hóa, mất đoạn và lặp đoạn lớn (>50bp), những
                đoạn lặp liên tục (nucleotide repeat expansion), vùng giàu CG, biến đổi số lượng bản sao của gene.
                <br />4.Chỉ những đột biến có ý nghĩa điều trị với thông tin lâm sàng của người tham gia xét nghiệm mới được thông báo. Vì vậy,
                thông tin lâm sàng không chính xác hoặc không đầy đủ có thể ảnh hưởng đến việc diễn giải kết quả xét nghiệm.
                <br />5.Một kế quả âm tính không hoàn toàn loại bỏ nguyên nhân do đột biến gene, do các đột biến khác hoặc gene gây bệnh không
                được khảo sát trong xét nghiệm này.
                <br />6.Kết quả xét nghiệm chỉ thể hiện đặc điểm đột biến của mẫu bệnh phẩm cung cấp. Do tính đa dạng đột biến của khối u, kết quả không phản ánh đặc điểm đột biến tại vị trí lấy mẫu khác hay thời điểm lấy mẫu khác nhau. Những trường hợp này có thể gây ra hiện tượng âm tính giả hay dương tính giả
                <br />7.Tùy thuộc chất lượng của mẫu bệnh phẩm cung cấp, xét nghiệm có thể không thực hiện được hay không có kết quả do hàm lượng DNA thấp hay mẫu ADN bị phân hủy quá nhiều
                <br />
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/@core/utils/filter";
import { mapState } from "vuex";
import HeaderForm from "@/views/PrintOrderForm/HeaderForm.vue";

const DEFAULT_FORM = {
  tki_code: "",
  force_treatment: "",
  factory_code: "",
  full_name: "",
  tki: null,
  identity_card_number: "",
  hospital: "",
  doctor: "",
  patient_email: "",
  smoke: null,
  disease_phase: "",
  operate_result: "",
  gpb_code: "",
  sample_day: "",
  position: "",
  discover_time: "",
  treatment: "",
  disease_sample: null,
  blood_sample: null,
  diagnosis: "",
  insurance: null,
  medical_record_no: null,
  gender: null,
  urgency: null,
  other_instruction: "",
  physician_name: "",
  contact_number: null,
  email: "",
  nsr_no: null,
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: "",
  // employees and the ones who let your borrow stuff
  employees: "",
  borrow_place: ""
};

export default {
  name: "PrintOrderForm",
  components: {
    HeaderForm
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.handleMapData();
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    handlePrint() {
      document.title = "Requisition-form";
      window.print();
    },
    handleMapData() {
      this.form = this.orders.requisitionFormV2 || { ...DEFAULT_FORM };
    },
    backToDetail() {
      document.title = "HODO Partner";
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders?.viewDetail?.id
        },
        query: {
          step: 1
        }
      });
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  margin-top: 6rem;

  .right {
    margin-left: 30rem;
  }
}
.container-border {
  // border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.above {
  margin-top: 5rem;
}

.html2pdf__page-break {
  page-break-before: always;
}
.part-6 {
  margin-top: 1.5rem;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 3px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

// .cs-label {
//   width: 150px;
// }

// .cs-border-b {
//   border-bottom: 1px solid #5c5c5c;
// }
// .cs-border-t {
//   border-top: 1px solid #5c5c5c;
// }

.letter {
  font-size: 18px;
}

.flex-letter {
  display: flex;
  flex-direction: row;
  .flex-left {
    display: flex;
    flex-direction: column;
  }

  .flex-right {
    display: flex;
    flex-direction: column;
  }
}

.check-box-master {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  .check-box-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .check-box-2 {
    display: flex;
    flex-direction: column;
  }
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.tki-section {
  margin-bottom: 2rem;
}
.cs-treatment {
  margin-left: 15rem;
}
.cs-sample {
  flex: 3;
}

.cs-smoke {
  margin-left: 7rem;
}

.cs-check {
  margin-left: 7rem;
}
.cs-email {
  margin-right: 24rem;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  // border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: #0569c5;
  font-size: 36px !important;
}

@media print {
  body,
  html {
    height: 100vh;
    background-color: #fff;
    *  {
      color: black !important;
    }
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>